import Sidebar from "./Sidebar";
import React, { useRef, useState } from "react";
import { TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon } from "react-share";
import { useReactToPrint } from "react-to-print";
import parse from "html-react-parser";
import Recherche from "./Recherche";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import {
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
  ChatBubbleOvalLeftIcon,
  EnvelopeIcon,
  FolderIcon,
  HandThumbUpIcon,
  LinkIcon,
  PrinterIcon,
} from "@heroicons/react/20/solid";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FacebookShareButton, FacebookIcon } from "react-share";

function Tree() {
  const [resize, setResize] = useState(false);
  const [data, setData] = useState("");
  const [search, setSearch] = useState("");

  let url = "https://www.npmjs.com/package/react-share-social";

  const ref = useRef();

  const handlePrint = useReactToPrint({
    content: () => ref.current,
  });

  const copy = async () => {
    await navigator.clipboard.writeText(window.location);
    toast.success("URL copied");
  };

  function handleResize() {
    setResize(!resize);
  }

  return (
    <div className="flex flex-col w-full">
      <ToastContainer autoClose={2000} theme="light" />
      <div className="h-[90vh] file-tree grid grid-cols-12 gap-4 overflow-hidden border p-5 bg-white rounded-lg mt-20">
        <div
          className={`
        transition-all  duration-700  
        
        
        ${
          resize
            ? " -translate-x-full h-0 col-span-1"
            : "h-[94%]  lg:col-span-4 translate-x-0 col-span-12"
        } 
        `}
        >
          <Sidebar setData={setData} />
        </div>
        <div
          className={` ${
            !resize ? " col-span-12 lg:col-span-8" : "col-span-10"
          } flex flex-col h-full overflow-hidden w-full`}
        >
          <div className="flex justify-end items-center gap-2">
            <FacebookShareButton url={url} quote={data}>
              <FacebookIcon size={32} round />
            </FacebookShareButton>

            <TwitterShareButton url={url} quote={data}>
              <TwitterIcon size={32} round />
            </TwitterShareButton>

            <WhatsappShareButton url={url} quote={data}>
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>

            <a className="btn btn-link bg-gray-400 rounded-full p-1" href={`mailto:test@gmail.com`}>
              <EnvelopeIcon className="w-6 h-6 text-white" />
            </a>
          </div>
          <div className="flex items-center justify-between gap-4 mt-3">
            <div className="flex items-center ">
              <button onClick={handlePrint} className="border-2 p-4">
                <PrinterIcon className="w-10 h-10  " />
              </button>
              <button onClick={copy} className="border-2 p-4">
                <LinkIcon className="w-10 h-10" />
              </button>
              <button className="border-2 p-4">
                <FolderIcon className="w-10 h-10" />
              </button>
              <button className="border-2 p-4">
                <HandThumbUpIcon className="w-10 h-10" />
              </button>
              <button className="border-2 p-4">
                <ChatBubbleOvalLeftIcon className="w-10 h-10" />
              </button>
              {resize ? (
                <button onClick={handleResize} className="flex border-2 p-4">
                  <ArrowsPointingInIcon className="w-10 h-10" />
                </button>
              ) : (
                <button onClick={handleResize} className="flex border-2 p-4">
                  <ArrowsPointingOutIcon className="w-10 h-10" />
                </button>
              )}
            </div>
            <div>
              <input
                className="bg-gray-100 p-2 border rounded-lg z-50"
                onChange={(e) => setSearch(e.target.value)}
                value={search}
                id="seacg"
                type="text"
                placeholder="Recherche..."
                aria-label="Search"
              />
            </div>
          </div>
          <div className="py-2"></div>
          <div
            className="sun-editor w-full not-selected flex-grow overflow-x-hidden overflow-y-auto "
            ref={ref}
          >
            <div className="card-body not-selected p-3 rounded  ">
              <div className="">
                {/* <h1 className="text-center mb-5 mt-2">{data.libelle}</h1>
                se-wrapper se-wrapper-inner se-wrapper-wysiwyg sun-editor-editable
                <div dangerouslySetInnerHTML={{ __html: data.contenu }}></div> */}
                {data.contenu ? (
                  search ? (
                    <div className="flex flex-col leading-6	">
                      <Recherche
                        htmltext={parse(
                          `<div className="text-center text-3xl font-bold mb-5 mt-2">${data.libelle}</div>` +
                            data.contenu
                        )}
                        search={[search]}
                      />
                    </div>
                  ) : (
                    <div className="flex flex-col">
                      <div className="text-center text-3xl font-bold mb-5 mt-2">{data.libelle}</div>
                      <div
                        className="leading-6 text-lg"
                        dangerouslySetInnerHTML={{ __html: data.contenu }}
                      ></div>
                    </div>
                  )
                ) : (
                  <div className="flex flex-col">
                    <div className="text-center text-3xl font-bold mb-5 mt-2">{data.libelle}</div>
                    <p className="text-lg font-semibold">"No article found"</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tree;
