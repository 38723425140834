import React from "react";
import logoc from "../logocdc.svg";
import ligomini from "../logocdc-mini.svg";
import { ArrowLeftOnRectangleIcon } from "@heroicons/react/20/solid";

import useLogout from "../hooks/useLogout";
import { useNavigate } from "react-router-dom";
function Navbars() {
  const navigate = useNavigate();
  const logout = useLogout();
  const logouts = () => {
    logout();
    console.log("logout");
  };
  return (
    <nav
      style={{ backgroundColor: "rgb(175 207 223)", color: "whitesmoke" }}
      className="  fixed w-full z-20 top-0 left-0 border-b border-gray-200 "
    >
      <div className="max-w-screen mx-20 flex  items-center justify-between p-4">
        <div>
          <button
            onClick={() => navigate("/")}
            className=" items-center hidden md:flex"
          >
            <img src={logoc} width={130} />
          </button>
          <a
            href="https://flowbite.com/"
            className=" items-center  flex md:hidden"
          >
            <img src={ligomini} width={30} />
          </a>
        </div>

        <div
          className="items-center justify-between hidden w-full md:flex md:w-auto"
          id="navbar-sticky"
        >
          <ul
            className="flex flex-col p-4 md:p-0 mt-4 font-bold border border-gray-100 rounded-lg md:flex-row md:space-x-8 md:mt-0 md:border-0 text-xl  items-center"
            style={{ backgroundColor: "rgb(175 207 223)" }}
          >
            <li>
              <button
                onClick={() => navigate("/")}
                className="block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-[#199bd8] hover:opacity-50 md:p-0 "
                aria-current="page"
              >
                Home
              </button>
            </li>
            <li>
              <a
                href="#"
                className="block py-2 pl-3 pr-4  rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-[#199bd8] md:p-0 transition-all duration-100 cursor-pointer "
              >
                About
              </a>
            </li>
            <li>
              <a
                href="#"
                className="block py-2 pl-3 pr-4 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-[#199bd8] md:p-0  transition-all duration-100 cursor-pointer"
              >
                Services
              </a>
            </li>
            <li>
              <a
                href="#"
                className="block py-2 pl-3 pr-4  rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-[#199bd8] md:p-0  transition-all duration-100 cursor-pointer"
              >
                Contact
              </a>
            </li>
          </ul>
        </div>
        <div>
          <ArrowLeftOnRectangleIcon
            className="w-6 h-6  transition-all duration-100 text-[#199bd8] hover:text-red-500 cursor-pointer"
            onClick={logouts}
          />
        </div>
      </div>
    </nav>
  );
}

export default Navbars;
