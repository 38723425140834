import Highlighter from "react-highlight-words";

const ResearchElem = ({ ele, search }) => {
  if (ele && ele.props != undefined) {
    const Custum = ele.type;
    const classname = ele.props.className;
    const style = ele.props.style;

    if (typeof ele.props.children == "object") {
      if (Array.isArray(ele.props.children)) {
        if (Custum === "br") {
          return <br />;
        } else if (Custum === "img") {
          return <img src={ele.props.src} alt={ele.props.alt} />;
        } else {
          return (
            <Custum className={classname} style={style}>
              {ele.props.children.map((child, index) => {
                if (typeof child == "object") {
                  return (
                    <ResearchElem
                      key={index}
                      ele={child}
                      search={search}
                    ></ResearchElem>
                  );
                } else {
                  return (
                    <span key={index}>
                      <Highlighter
                        highlightClassName="High"
                        searchWords={search}
                        autoEscape={true}
                        textToHighlight={child}
                      />
                    </span>
                  );
                }
              })}
            </Custum>
          );
        }
      } else {
        if (Custum === "br") {
          return <br />;
        } else if (Custum === "img") {
          return <img src={ele.props.src} alt={ele.props.alt} />;
        } else {
          return (
            <Custum className={classname} style={style}>
              <ResearchElem
                ele={ele.props.children}
                search={search}
              ></ResearchElem>
            </Custum>
          );
        }
      }
    } else {
      return (
        <Custum className={classname} style={style}>
          <Highlighter
            highlightClassName="High"
            searchWords={search}
            autoEscape={true}
            textToHighlight={ele.props.children}
          />
        </Custum>
      );
    }
  } else {
    <></>;
  }
};

export default ResearchElem;
