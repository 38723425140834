import React, { useState } from "react";
import NavbarAdmin from "./NavbarAdmin";
import Content from "./Content";
import TreeSideBar from "./TreeSideBar";

function Index() {
  const [selectedSommaire, setSelectedSommaire] = useState(null);
  const [value, setValue] = useState(""); //[selectedSommaire?.contenu
  const [changed, setChanged] = useState(false); //
  return (
    <div>
      <NavbarAdmin />
      <div className="grid grid-cols-12 gap-x-7 mt-32 px-10 h-[80vh] overflow-hidden">
        <div className="col-span-3 w-full">
          {/* selectedSommaire={selectedSommaire} */}
          <TreeSideBar
            setSelectedSommaire={setSelectedSommaire}
            selectedSommaire={selectedSommaire}
            setValue={setValue}
            changed={changed}
            setChanged={setChanged}
          />
        </div>
        <div className="col-span-9 w-full">
          <Content
            selectedSommaire={selectedSommaire}
            setSelectedSommaire={setSelectedSommaire}
            value={value}
            key={selectedSommaire?.id}
            setChanged={setChanged}
          />
        </div>
      </div>
    </div>
  );
}

export default Index;
