import React from "react";
import logoc from "../../logocdc-mini.svg";
import { ArrowLeftOnRectangleIcon } from "@heroicons/react/20/solid";
import useLogout from "../../hooks/useLogout";
import { useNavigate } from "react-router-dom";
function NavbarAdmin() {
  const navigate = useNavigate();
  const logout = useLogout();
  const logouts = () => {
    logout();
    console.log("logout");
  };
  return (
    <nav className=" bg-slate-700  fixed w-full z-20 top-0 left-0 border-b border-gray-200 ">
      <div className="max-w-screen flex flex-wrap items-center justify-between mx-20 p-4">
        <button
          onClick={() => navigate("/")}
          className=" items-center hidden md:flex text-white"
        >
          <img src={logoc} width={50} />
          <span className="text-2xl ml-5 font-bold">Admin</span>
        </button>

        <div
          className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
          id="navbar-sticky"
        >
          <ul className="flex flex-col p-4 md:p-0 mt-4 font-bold border border-gray-100 rounded-lg md:flex-row md:space-x-8 md:mt-0 md:border-0 text-xl  items-center">
            <li>
              <ArrowLeftOnRectangleIcon
                className="w-6 h-6  transition-all ease-in duration-150 text-[#199bd8] hover:text-white hover:opacity-80  cursor-pointer"
                onClick={logouts}
              />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default NavbarAdmin;
