import React, { useEffect, useState } from "react";
import loginC from "../logocdc.svg";

import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";

import http from "../http";
function Login() {
  const navigate = useNavigate();
  // const location = useLocation();
  const { setAuthState } = useAuth();

  const [identifiant, setIdentifiant] = useState("");
  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const loginResponse = await http.post("/login", {
        identifiant,
        password,
      });

      setAuthState({
        accessToken: loginResponse.data.token,
        userId: loginResponse.data.iduser,
        identifiant,
        password,
        idatome: loginResponse.data.idatome,
      });
      localStorage.setItem("accessToken", loginResponse.data.token);
      localStorage.setItem("iduser", loginResponse.data.iduser);
      localStorage.setItem("identifiant", identifiant);
      localStorage.setItem("idatome", loginResponse.data.idatome);
      localStorage.setItem("lang", loginResponse.data.langue);
      sessionStorage.setItem("key", password);
      // reset local state and navigate
      setIdentifiant("");
      setPassword("");
      setError("");

      navigate("/");
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-2 space-y-4 md:space-y-6 sm:p-8">
            <a
              href="#"
              className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
            >
              <img className="w-48 h-full m-auto" src={loginC} alt="logo" />
            </a>
            <form className="space-y-4 md:space-y-6" action="#">
              <div>
                <label
                  for="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Your email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  onChange={(e) => setIdentifiant(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="name@company.com"
                  required
                />
              </div>
              <div>
                <label
                  for="password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="••••••••"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full text-white bg-primary-600 hover:opacity-50 cursor-pointer transition-all duration-100 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-blue-500"
                onClick={handleLogin}
              >
                Sign in
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
