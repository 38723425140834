import { Route, Routes } from "react-router-dom";
import Tree from "./components/Tree";
import Navbars from "./components/Navbars";
import Login from "./components/Login";

import Index from "./components/admin";

import PersistLogin from "./autre/PersistAuth";
import RequireAuth from "./autre/RequireAuth";
function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth />}>
            <Route path="/admin" element={<Index />} />
            <Route
              path="/"
              element={
                <>
                  <Navbars />
                  <Tree />
                </>
              }
            />
          </Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
