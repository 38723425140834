import { FolderIcon, FolderOpenIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import Spinner from "../../autre/Spinner";
import { useNavigate } from "react-router-dom";

const SideBar = ({ setData }) => {
  const [roots, setRoots] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const url_racine = process.env.REACT_APP_TREE_URL_SOMAIRE_RACINE;
  const queryParams = new URLSearchParams(window.location.search);
  const [pays, setPays] = useState("");
  const filtredRoot =
    selectedItem !== ""
      ? roots.filter((sl) => sl?.libelle?.toLowerCase() === selectedItem?.toLowerCase())
      : roots.filter((sl) => sl?.libelle?.toLowerCase() === pays?.toLowerCase());

  const history = useNavigate();
  //  sl.libelle.toLowerCase() === pays.toLowerCase()
  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await axios.put(url_racine);
      setRoots(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const qValue = queryParams.get("pays");
    setPays(qValue);
  }, [queryParams]);
  function IndexChange(value) {
    setSelectedItem(value);
    history(`?pays=${value}`);
  }

  return (
    <div className="h-full">
      <div className="h-fit">
        {!loading ? (
          <select
            onChange={(e) => IndexChange(e.target.value)}
            className="text-lg cursor-pointer rounded p-1 border-2 border-[#199bd8]"
          >
            {roots?.map((itm) => (
              <option
                key={itm.id}
                value={itm?.libelle}
                selected={pays?.toLowerCase() === itm?.libelle?.toLowerCase()}
              >
                {itm.libelle}
              </option>
            ))}
          </select>
        ) : (
          <div className="flex items-center gap-3 text-lg">
            <div>
              <Spinner />
            </div>
            loading...
          </div>
        )}
      </div>
      <div className="max-h-[83vh] h-[83vh] overflow-scroll">
        <ul className="w-full pt-5  ">
          {filtredRoot?.map((item) => (
            <Item setData={setData} key={item.id} item={item} />
          ))}
        </ul>
      </div>
    </div>
  );
};

const Item = ({ item, setData }) => {
  const [children, setChildren] = useState([]);
  const [showChildren, setShowChildren] = useState(false);
  const url_filles = process.env.REACT_APP_TREE_URL_FILLE;

  const fetchChildren = async (id) => {
    try {
      if (children.length) return;
      const response = await axios(url_filles, {
        method: "PUT",
        headers: {
          "content-type": "application/json",
        },
        data: {
          idsommaire: id,
        },
      });
      setChildren(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = () => {
    setShowChildren((prev) => !prev);
    fetchChildren(item.id);
    handelEdit(item);
  };
  const handelEdit = () => {
    setData(item);
  };

  return (
    <div className="flex flex-col gap-2 ">
      <div className="flex w-full gap-1 tst">
        <button onClick={handleClick} className={` text-[#199bd8]  `}>
          {showChildren ? <FolderOpenIcon className="w-5 " /> : <FolderIcon className="w-5" />}
        </button>
        <div
          onClick={handleClick}
          title={item.libelle}
          data-title="My site"
          className={`class-with-tooltip hover:shadow-lg text-[1rem] rounded-md  max-w-md truncate   text-ellipsis overflow-hidden  text-black   p-1   cursor-pointer transition-all duration-100        ${
            showChildren && "  font-bold"
          }  `}
        >
          {item.libelle}
        </div>
      </div>

      {children.length > 0 &&
        showChildren &&
        children.map(
          (child) =>
            child.id !== item.id && (
              <div className="ml-9  w-full " key={child.id}>
                <Item item={child} setData={setData} />
              </div>
            )
        )}
    </div>
  );
};

export default SideBar;
