import useAuth from "./useAuth";
import useOrganism from "./useOrganism";

const useLogout = () => {
  const { setAuthState } = useAuth();

  const logout = async () => {
    setAuthState({});
    // localStorage.removeItem("accessToken");
    // localStorage.removeItem("organisms");
    // localStorage.removeItem("selectedOrganism");
    // localStorage.removeItem("iduser");
    localStorage.clear();
  };

  return logout;
};

export default useLogout;
