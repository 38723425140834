import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const RequireAuth = () => {
  const location = useLocation();
  const { authState } = useAuth();

  // prettier-ignore
  return authState?.accessToken
        ? <Outlet />
        : <Navigate to="/login" state={{ from: location }} replace />;
};

export default RequireAuth;
