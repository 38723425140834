import {
  ChevronRightIcon,
  PencilSquareIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/20/solid";

import React, { useCallback, useEffect, useRef, useState } from "react";
import useHttpPrivate from "../../hooks/useHttpPrivate";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// selectedSommaire
const TreeSideBar = ({
  setSelectedSommaire,
  setValue,
  setChanged,
  changed,
  selectedSommaire,
}) => {
  const [roots, setRoots] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const http = useHttpPrivate();
  // FETCH DATA
  const fetchData = useCallback(() => {
    http
      .put("/sommairesracine")
      .then((res) => {
        setRoots(res.data);
        !selectedItem && setSelectedItem(res.data[0].id);
      })
      .catch((err) => console.log(err));
  }, []);
  // refrech data
  const handleChange = (id) => {
    setSelectedItem(id);
  };
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="h-full">
      <div>
        <select
          onChange={(e) => handleChange(e.target.value)}
          className="text-lg cursor-pointer w-1/2 rounded p-1 border-2 border-[#199bd8]"
          defaultValue={roots[0]?.id}
        >
          {roots.map((itm) => (
            <option key={itm.id} value={itm.id}>
              {itm.libelle}
            </option>
          ))}
        </select>
      </div>
      <div className="max-h-[83vh] h-[83vh] overflow-scroll">
        <ul className="w-full pt-5">
          {roots
            .filter((sl) => sl.id === selectedItem)
            .map((item) => (
              <Item
                setSelectedSommaire={setSelectedSommaire}
                key={item.id}
                item={item}
                setValue={setValue}
                fetchData={fetchData}
                setChanged={setChanged}
                changed={changed}
                selectedSommaire={selectedSommaire}
              />
            ))}
        </ul>
      </div>
    </div>
  );
};

const Item = ({
  item,
  setSelectedSommaire,
  fetchData,
  setValue,
  changed,
  setChanged,
  selectedSommaire,
}) => {
  const [children, setChildren] = useState([]);
  const [showChildren, setShowChildren] = useState(false);
  const [draggedText, setDraggedItem] = useState();
  const [MyItem, setMyItem] = useState(item);
  const [isDragging, setIsDragging] = useState(false);
  const [lastY, setLastY] = useState(0);
  const scrollContainerRef = useRef(null);
  const http = useHttpPrivate();
  // FETCH CHILDREN
  const fetchChildren = async (id) => {
    try {
      const response = await http.put("/sommairesfilles", {
        idsommaire: id,
      });
      setChildren(response.data);
      console.log("fetchChildren", response.data);
    } catch (error) {
      console.log(error);
    }
  };
  // SHOW CHILDREN ON CLICK
  const handleClick = () => {
    setShowChildren((prev) => !prev);
    fetchChildren(item.id);
    setSelectedSommaire(item);
    setValue("Update");
    console.log("handleClick", item);
  };
  // UPDATE
  const handleUpdate = (e) => {
    e.preventDefault();
    setSelectedSommaire(item);
    setValue("Update");
  };
  // ADD
  const handleAdd = (e) => {
    e.preventDefault();
    setSelectedSommaire(item);
    setValue("Add");
    fetchChildren(item.id);
  };
  // DELETE
  const deleteItem = async (e) => {
    e.preventDefault();

    try {
      const { data } = await http.put("/sommairesfilles", {
        idsommaire: item.id,
      });
      if (data.length > 0) {
        toast.error("You can't delete this item since it has children");
        return;
      }
      await http.patch("/sommaires", {
        ...item,
        idstatut: -2,
      });
      console.log("deleted");
      toast.success("Item deleted successfully");
      setChanged(!changed);
    } catch (error) {
      console.error(error);
    }
  };
  const hadleDelete = (e) => {
    confirmAlert({
      title: "Delete item",
      message: "Are you sure to do delete " + item.libelle + " ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteItem(e),
        },
        {
          label: "No",
          // onClick: () => alert("Click No"),
        },
      ],
    });
  };

  // DRAG AND DROP CHANGE PARENT
  const handleDragStart = (event, item) => {
    event.dataTransfer.setData("text", event.target.innerText);
    event.dataTransfer.setData("item", JSON.stringify(item));
    setDraggedItem(item);
    setSelectedSommaire(item);
    setChanged(!changed);
  };

  const OnDragOver = (e) => {
    e.preventDefault();
  };

  const OnDrop = async (e, item) => {
    const DraggedItem = JSON.parse(e.dataTransfer.getData("item"));
    await http.patch("/sommaires", {
      ...DraggedItem,
      idsommaire: item.id,
    });
    setSelectedSommaire(item);
    // fetch data from server
    setChanged(!changed);
    fetchChildren(item.id);
    fetchData();
  };
  // DRAG AND DROP CHANGE ORDRE
  const handleDragStartOrdre = (event, item) => {
    event.dataTransfer.setData("text", event.target.innerText);
    event.dataTransfer.setData("item", JSON.stringify(item));
    setDraggedItem(item);
  };

  const OnDropOrdre = async (e, item) => {
    const DraggedItem = JSON.parse(e.dataTransfer.getData("item"));
    await http.patch("/sommaires", {
      ...DraggedItem,
      ordre: item.ordre,
    });
    await http.patch("/sommaires", {
      ...item,
      ordre: DraggedItem.ordre,
    });
    toast.success("Ordre changed successfully");
    setChanged(!changed);
    // fetch data from server
    fetchData();
  };

  // Scrole

  // const handelMouseDown = (e) => {
  //   setIsDragging(true);
  //   setLastY(e.clientY);
  // };

  // const handelMouseUp = (e) => {
  //   setIsDragging(false);
  // };

  // const handelMouseMove = (e) => {
  //   if (isDragging) {
  //     scrollContainerRef.current.scrollTop += e.clientY - lastY;
  //     setLastY(e.clientY);
  //   }
  // };

  useEffect(() => {
    if (item.id === selectedSommaire?.id) {
      fetchChildren(item.id);
      setMyItem(selectedSommaire);
      setShowChildren((prev) => !prev);
      setShowChildren((prev) => !prev);
      console.log("useEffect 1", item);
    }
    if (item.id === selectedSommaire?.idsommaire) {
      fetchChildren(item.id);
      setShowChildren((prev) => !prev);
      setShowChildren((prev) => !prev);
      console.log("useEffect 2", item);
    }
    // if (selectedSommaire?.id === item.idsommaire) {
    //   fetchChildren(item.id);
    //   setMyItem(selectedSommaire);
    // }
  }, [changed]);
  // RENDER

  return (
    <div
      className={`flex flex-col gap-1 my-2 w-fit`}
      id={`${item.id}/${item.idsommaire}`}
      // onMouseDown={handelMouseDown}
      // onMouseUp={handelMouseUp}
      // onMouseMove={handelMouseMove}
      ref={scrollContainerRef}
    >
      <div className="flex w-full gap-1 items-center">
        <button
          draggable
          onDragStart={(e) => handleDragStartOrdre(e, item)}
          onDrop={(e) => OnDropOrdre(e, item)}
          onClick={handleClick}
          onDragOver={OnDragOver}
          className={`${showChildren && "rotate-90"} text-[#199bd8]`}
        >
          <ChevronRightIcon className="w-5" />
        </button>
        <div
          draggable
          onDragStart={(e) => handleDragStart(e, item)}
          onDrop={(e) => OnDrop(e, item)}
          onClick={handleClick}
          onDragOver={OnDragOver}
          title={MyItem.libelle}
          className=" text-black font-bold  rounded max-w-[160px]  hover:bg-[#199bd8]  text-ellipsis overflow-hidden  whitespace-nowrap   py-0.5 px-2  hover:text-black cursor-pointer transition-all duration-100"
        >
          {MyItem.libelle}
        </div>
        <div className="flex pl-3 items-center ">
          <PencilSquareIcon
            onClick={handleUpdate}
            className="w-5 h-5 hover:opacity-50 transition-all duration-100 ease-out cursor-pointer"
          />
          <PlusIcon
            onClick={handleAdd}
            className="w-6 h-6 hover:opacity-50 transition-all duration-100 ease-out cursor-pointer"
          />
          <TrashIcon
            onClick={hadleDelete}
            className="w-5 h-5 text-red-500 hover:opacity-50 transition-all duration-100 ease-out cursor-pointer"
          />
        </div>
      </div>
      {children.length > 0 &&
        showChildren &&
        children.map(
          (child) =>
            child.id !== item.id && (
              <div className="ml-9  w-full " key={child.id}>
                <Item
                  item={child}
                  setSelectedSommaire={setSelectedSommaire}
                  fetchData={fetchData}
                  setValue={setValue}
                  changed={changed}
                  setChanged={setChanged}
                  selectedSommaire={selectedSommaire}
                />
              </div>
            )
        )}
    </div>
  );
};

export default TreeSideBar;
