import React from "react";
import TextInput from "../common/TextInput";
import Wysiwyg from "../common/Wysiwyg";
import useHttpPrivate from "../../hooks/useHttpPrivate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Content = ({
  selectedSommaire,
  value,
  setChanged,
  setSelectedSommaire,
}) => {
  const http = useHttpPrivate();
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (value === "Update") {
      try {
        const formData = new FormData(e.currentTarget);
        const data = {};

        for (let [key, value] of formData.entries()) {
          data[key] = value;
        }
        console.log(data);
        await http.patch("/sommaires", {
          ...selectedSommaire,
          ...data,
        });
        toast.success("Sommaire updated successfully");
        setSelectedSommaire({
          ...selectedSommaire,
          ...data,
        });
        setChanged((prev) => !prev);
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        const formData = new FormData(e.currentTarget);
        const data = {};

        for (let [key, value] of formData.entries()) {
          data[key] = value;
        }
        console.log(data);
        await http.post("/sommaires", {
          ...data,
          idsommaire: selectedSommaire.id,
        });
        toast.success("Sommaire created successfully");
        setChanged((prev) => !prev);
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <form
      className="w-full"
      onSubmit={handleSubmit}
      key={`${selectedSommaire?.id}/${value}`}
    >
      <ToastContainer />

      <div className="flex gap-2 w-full">
        <TextInput
          label="Label"
          idname="libelle"
          placeholder={selectedSommaire?.libelle}
          defaultValue={value === "Update" ? selectedSommaire?.libelle : ""}
          className="flex-1"
          required
        />

        <TextInput
          label="Ordre"
          type="number"
          idname="ordre"
          className="flex-1"
          defaultValue={value === "Update" ? selectedSommaire?.ordre : 1}
          required
        />
      </div>
      <div className="flex gap-2 w-full">
        <TextInput
          label="Code"
          idname="code"
          className="flex-1"
          defaultValue={value === "Update" ? selectedSommaire?.code : ""}
        />
        <TextInput
          label="Page"
          type="number"
          idname="page"
          className="flex-1"
          defaultValue={value === "Update" ? selectedSommaire?.page : ""}
        />
      </div>
      <div className="flex flex-col">
        <label htmlFor="contenu">Content</label>
        <div className="flex-grow h-[55vh] max-h-[55vh] overflow-y-auto overflow-x-hidden">
          <Wysiwyg
            key={selectedSommaire?.id}
            name="contenu"
            defaultValue={value === "Update" ? selectedSommaire?.contenu : ""}
          />
        </div>
      </div>
      <div className=" pt-2 block">
        {value && (
          <button
            type="submit"
            className="float-right bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded"
          >
            {value}
          </button>
        )}
        {/* <button
          type="button"
          onClick={hadleDelete}
          className="float-right bg-red-700 hover:bg-red-800 text-white font-bold py-2 px-4 rounded mr-2"
        >
          Delete
        </button> */}
      </div>
    </form>
  );
};

export default Content;
