import axios from "axios";

const http = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

export const avapi = axios.create({
  baseURL: process.env.REACT_APP_AML_API_BASE_URL,
});

export const aml = axios.create({
  baseURL: process.env.REACT_APP_AML_API_BASE_URL,
});

export default http;
