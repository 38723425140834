import { Outlet, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";
import OverlaySpinner from "./OverlaySpinner";

const PersistLogin = () => {
  const navigate = useNavigate();
  const { authState, setAuthState } = useAuth();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    const iduser = localStorage.getItem("iduser");
    const identifiant = localStorage.getItem("identifiant");
    const key = sessionStorage.getItem("key");
    const idatome = localStorage.getItem("idatome");
    const lang = localStorage.getItem("lang")
    if (!token || !iduser) {
      navigate("/login");
      return;
    }
    setAuthState({ accessToken: token, userId: iduser, identifiant, password: key, idatome, lang });
    setLoading(false);
  }, [navigate, setAuthState]);

  if (loading) return <OverlaySpinner />;

  return <Outlet />;
};

export default PersistLogin;
